import React, { Component } from "react";

class NFBDetail extends Component {
    render() {
        return (
            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-large-size_1-of-4 slds-var-p-around_small">
                <div class="slds-grid slds-wrap slds-card">
                    <div class="slds-col slds-size_1-of-1 slds-var-p-around_small">
                        <img src={'./images/NFBs-' + this.props.id + '.png'} alt="nfb"></img>
                    </div>

                    <div class="slds-col slds-size_1-of-1 slds-var-p-horizontal_small slds-var-p-bottom_small">
                        <div class="">Burrito #{this.props.id}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NFBDetail;
