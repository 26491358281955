/** @format */

import React, { Component } from "react";
import NFBDetail from "./nfbDetail";
import { Route, NavLink, BrowserRouter } from "react-router-dom";

class NFBs extends Component {
    render() {
        let data = []; //todo: get nfb info from db
        for (let i = 1; i < 21; i++) {
            let id = i < 10 ? "0" + i.toString() : i.toString();
            let nfbData = { id: id };
            data.push(nfbData);
        }
        const listItems = data.map((d) => (
            <NFBDetail key={d.id} id={d.id}></NFBDetail>
        ));

        return (
            <div>
                <p class="slds-text-heading_large slds-var-p-top_small slds-align_absolute-center">
                    Non-Fungible Burritos
                </p>
                <p class="slds-text-title_caps slds-var-p-top_small slds-var-p-horizontal_small slds-align_absolute-center">
                    As requested by our users: a collection of 20 Burrito
                    NFBs—unique digital collectibles living on the Cryptorito
                    blockchain
                </p>
                <div class="slds-var-p-around_small">
                    <p class="slds-text-heading_medium">
                        Be Delicious
                    </p>
                    <p class="slds-text-heading_medium strikethrough">
                        Undeniably Unique
                    </p>
                </div>
                <p class="slds-var-p-horizontal_small">
                    Each NFB is unique and hand generated from over 50 possible
                    traits, including eyes, headwear, background, and more. All
                    'ritos look delicious, but some are more delicious looking
                    than others.
                </p>
                <p class="slds-text-heading_medium slds-var-p-around_small">
                    A Full Meal
                </p>
                <p class="slds-var-p-horizontal_small">
                    Included with ownership of an NFB are a few special
                    ingredients:
                </p>
                <ul class="slds-var-p-horizontal_small slds-list_dotted slds-var-p-top_x-small">
                    <li>
                        Access to "The Fillings", an owners-only area of
                        YoBurrito.app
                    </li>
                    <li>
                        An increased Burrito giving limit - up to 10 per day!
                    </li>
                    <li>
                        Additional features to come
                    </li>
                </ul>
                <p class="slds-text-heading_medium slds-var-p-horizontal_small slds-var-p-bottom_small">
                    Fair Chance Initial Purchase
                </p>
                <div class="slds-var-p-horizontal_small">
                    <p class=" slds-var-p-bottom_small">
                        All NFBs start at just 500 burritos. MarAbierto allows you to list, bid, and transfer ownership of NFBs.
                    </p>
                    <button class="slds-button slds-button_neutral">
                        {/* <NavLink
                            exact={true}
                            activeClassName="active"
                            to="/marabierto"
                        > */}
                            BUY AND SELL ON MARABIERTO
                        {/* </NavLink> */}
                    </button>
                </div>
                <div class="slds-grid slds-wrap slds-var-p-top_small">
                    {listItems}
                </div>
            </div>
        );
    }
}

export default NFBs;
