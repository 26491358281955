import React, { Component } from "react";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            receivedCount: 0,
            givenCount: 0,
            dailyAllowance: 5,
            crushName: '',
            crushCount: 0,
            admirerName: '',
            admirerCount: 0,
            lotteryWins: 0
        }
    }

    componentDidMount() {
        this.getUserInfo();
    }

    formatUsernameString(username) {
        if(!username) {
            return 'some other bot';
        }
        if(username.lastIndexOf("@") > 0) {
            return username.substring(0, username.lastIndexOf("@"));
        } else {
            return username;
        }
    }

    checkForData(data, attribute, returnVal) {
        if(data && data[attribute] !== undefined) {
            return data[attribute];
        } else {
            return returnVal;
        }
    };

    getUserInfo = () => {
        const userId = localStorage.getItem('burritoUserId');
        this.setState({userId:userId});
        let calloutURI = '/api/v1/user/'+userId;
        fetch(calloutURI, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function(response) {
			return response.json();
		})
		.then((responseJSON) =>{
            // console.log(JSON.stringify(responseJSON));
            this.setState({
                receivedCount: this.checkForData(responseJSON[0],'burritosCount',0),
                givenCount: this.checkForData(responseJSON[1],'burritosCount',0),
                crushName: this.formatUsernameString(this.checkForData(responseJSON[2],'given_to_username','No Crush found@wmp.com')),
                crushCount: this.checkForData(responseJSON[2],'burritosCount',0),
                admirerName: this.formatUsernameString(this.checkForData(responseJSON[3],'given_by_username','No Admirer found@wmp.com')),
                admirerCount: this.checkForData(responseJSON[3],'burritosCount',0),
                dailyAllowance: this.checkForData(responseJSON[4],'daily_allowance',5),
                lotteryWins: this.checkForData(responseJSON[5],'burritosCount',0)
            });
        })
        .catch((error) => {
            console.log('callout error ===> '+error);
        });
    };

    render() {
        let userInfo;
        if(this.state.userId) {
            userInfo =
                <React.Fragment>
                    <div class="slds-size_1-of-1 slds-card slds-box">
                        <p class="slds-text-heading_large slds-text-align_center">Your <span role="img" aria-label="Burrito">🌯</span> Stats</p>
                        <div class="slds-grid slds-wrap">
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">Remaining Today</p>
                                <p class="slds-text-heading_small">{this.state.dailyAllowance}</p>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">Lottery Wins</p>
                                <p class="slds-text-heading_small">{this.state.lotteryWins}</p>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">Total Received</p>
                                <p class="slds-text-heading_small">{this.state.receivedCount}</p>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">Total Given</p>
                                <p class="slds-text-heading_small">{this.state.givenCount}</p>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">Your Crush</p>
                                <p class="slds-text-heading_small">{this.state.crushName} - {this.state.crushCount}</p>
                            </div>
                            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2 slds-var-p-vertical_x-small">
                                <p class="slds-text-heading_medium">Your Admirer</p>
                                <p class="slds-text-heading_small">{this.state.admirerName} - {this.state.admirerCount}</p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
        } else {
            userInfo =
                <React.Fragment>
                    <p class="slds-text-heading_medium">Hmm...I can't find a user id for you. Try going back to Slack and clicking the "Open Site" button.</p>
                </React.Fragment>
        }
        return (
            <div class="slds-grid slds-wrap">
                {userInfo}
            </div>
        );
    }
}

export default UserInfo;