import React, { Component } from "react";

class Fools extends Component {
    render() {
        return (
            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-large-size_1-of-4 slds-var-p-around_small">
                jk lol
            </div>
        );
    }
}

export default Fools;
