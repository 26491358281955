/** @format */

import React, { Component } from "react";
import { Route, NavLink, BrowserRouter } from "react-router-dom";
import Home from "./home";
import Leaderboards from "./leaderboards";
import NFBs from "./nfbs";
import Fools from "./fools";
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            stonkClass: "stonkImage",
            stonkClass2: "stonkImage hidden",
        };
    }
    componentWillMount() {
        let storage = window.localStorage;
        const userId = new URLSearchParams(window.location.search).get(
            "userId"
        );
        const existingUserId = storage.getItem("burritoUserId");
        if (userId && (!existingUserId || userId !== existingUserId)) {
            // console.log("setting userId from url");
            storage.setItem("burritoUserId", userId);
        }
        this.setState({
            userId: userId ? userId : storage.getItem("burritoUserId"),
        });
    }
    imageClick() {
        this.setState({ stonkClass: "stonkImage hidden" });
        this.setState({ stonkClass2: "stonkImage" });
    }
    render() {
        return (
            <BrowserRouter>
                <div class="pageContainer">
                    <p class="slds-text-heading_large">
                        <img
                            src={"/logo192.png"}
                            alt="rito"
                            class={this.state.stonkClass}
                            onClick={this.imageClick.bind(this)}
                        ></img>
                        <img
                            src={"/stonks.png"}
                            alt="Stonks"
                            class={this.state.stonkClass2}
                        ></img>
                        YoBurrito
                    </p>
                    <p class="slds-text-heading_small slds-var-p-bottom_small">
                        {this.state.userId}
                    </p>
                    <ul className="header">
                        <li>
                            <NavLink
                                exact={true}
                                activeClassName="active"
                                to="/"
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName="active"
                                to="/leaderboards"
                            >
                                Leaderboards
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/nfb">
                                NFBs
                            </NavLink>
                        </li>
                    </ul>
                    <div className="content">
                        <Route exact path="/" component={Home} />
                        <Route path="/leaderboards" component={Leaderboards} />
                        <Route path="/nfb" component={NFBs} />
                        <Route path="/marabierto" component={Fools} />
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

export default Main;
