import React, { Component } from "react";

class MostRecentBurrito extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: {},
        };
    }
    // Fetch passwords after first mount
    componentDidMount() {
        this.getBurrito();
    }

    getBurrito = () => {
        const userId = localStorage.getItem('burritoUserId');
        let calloutURI = '/api/v1';
        switch(this.props.queryType) {
            case 'user':
                calloutURI += '/user/' + userId +'/' + this.props.burritoMethod.toLowerCase();
                break;
            case 'lottery':
                calloutURI += '/lottery';
                break;
            default:
        }
        calloutURI += '/recent'
        fetch(calloutURI, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function(response) {
			return response.json();
		})
		.then((responseJSON) =>{
            if(Object.keys(responseJSON).length > 0) {
                responseJSON.message = responseJSON.message.replace(/<.*>/, '').replaceAll(':burrito:','🌯').trim();
                this.setState({result:responseJSON});
            } else {
                this.setState({result:null});
            }
        })
        .catch((error) => {
            console.log('callout error ===> '+error);
        });
    };

    render() {
        let mostRecent;
        if(this.state.result) {
            mostRecent =
                <React.Fragment>
                    <div class="slds-text-align_center slds-text-heading_large">
                        {this.props.label}
                    </div>
                    <div class="slds-text-align_center slds-text-heading_medium slds-var-p-top_small">
                        "{this.state.result.message}"
                    </div>
                    <div class="slds-grid slds-grid_vertical-align-center slds-var-p-top_small">
                        <div class="slds-col slds-size_1-of-2">
                        {new Intl.DateTimeFormat("en-US", {
                                year: "2-digit",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                timeZoneName: "short",
                                hour12: "true"
                            }).format(this.state.result.timestamp)}
                        </div>
                        <div class="slds-col slds-size_1-of-2">
                            <div class="slds-float_right slds-text-heading_small">
                                {this.props.creditLabel} {this.state.result.username}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
        } else {
            mostRecent =
                <React.Fragment>
                    No "{this.props.label}" <span role="img" aria-label="Burrito">🌯</span> found.
                </React.Fragment>
        }
        return (
            <div class="slds-grid slds-wrap">
                <div class="slds-card slds-box slds-size_1-of-1">
                    {mostRecent}
                </div>
            </div>
        );
    }
}

export default MostRecentBurrito;
