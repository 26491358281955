import React, { Component } from "react";

class Leaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            sortBy: 'total_burritos',
            sortDirection: 'sortDescend'
        };
        this.handleSort = this.handleSort.bind(this);
        this.getClassName = this.getClassName.bind(this);
    }

    componentDidMount() {
        this.getLeaderboard();
    }

    getClassName(name) {
        let className = 'slds-truncate sortable ';
        if(name !== this.state.sortBy) {
            return className += 'notSorted';
        } else {
            return className += this.state.sortDirection;
        }
    }

    handleSort(event) {
        const cloneData = [...this.state.results];
        const sortBy = event.target.id;
        const sortDirection = this.state.sortDirection === 'sortAscend' ? 'sortDescend' : 'sortAscend';
        this.setState({
            results: cloneData.sort(this.sortBy(sortBy, sortDirection === 'sortAscend' ? 1 : -1)),
            sortDirection: sortDirection,
            sortBy: sortBy
        });
    }

    sortBy(field, reverse, primer) {
        const key = primer
            ? function(x) {
                return primer(x[field]);
            }
            : function(x) {
                return x[field];
            };

        return function(a, b) {
            a = key(a);
            b = key(b);
            return reverse * ((a > b) - (b > a));
        };
    }

    getLeaderboard = () => {
        const cleanedTimeFrame = this.props.timeFrame === 'All-time' ? 'alltime' : 'quarterly';
        let calloutURI = '/api/v1/leaderboard/' + cleanedTimeFrame;
        // console.log('calloutURI: '+calloutURI);
        fetch(calloutURI, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function(response) {
			return response.json();
		})
		.then((responseJSON) =>{
            // console.log(JSON.stringify(responseJSON));
            this.setState({results:responseJSON});
        })
        .catch((error) => {
            this.setState({results:[]});
            console.log('callout error ===> '+error);
        });
    };

    render() {
        let leaderboard;
        if(this.state.results.length > 0) {
            leaderboard =
                <React.Fragment>
                    <div class="slds-size_1-of-1">
                        <p class="slds-text-heading_large slds-var-p-bottom_small">{this.props.timeFrame} Leaderboard</p>
                        <table class="tableClass">
                            <thead>
                                <tr class="">
                                    <th class={this.getClassName('username')} title="Teammate" onClick={this.handleSort} id="username">
                                        Teammate
                                    </th>
                                    <th class={this.getClassName('total_burritos')} title="Total Burritos" onClick={this.handleSort} id="total_burritos">
                                        Total Burritos
                                    </th>
                                    <th class={this.getClassName('total_given')} title="Total Given" onClick={this.handleSort} id="total_given">
                                        Total Given
                                    </th>
                                    <th class={this.getClassName('given_ratio')} title="Ratio" onClick={this.handleSort} id="given_ratio">
                                        Ratio
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.state.results).map((line, id) => {
                                    return (
                                        <tr class="" key={'row'+id}>
                                            <td class="slds-truncate" title="Teammate" data-label="Teammate" id={'username-'+id} key={'username-'+id}>
                                                <span class="dataSpace">
                                                    {line.username}
                                                </span>
                                            </td>
                                            <td class="slds-truncate" title="Total Burritos" data-label="Total Burritos" id={'total_burritos-'+id} key={'total_burritos-'+id}>
                                                <span class="dataSpace">
                                                    {line.total_burritos}
                                                </span>
                                            </td>
                                            <td class="slds-truncate" title="Total Given" data-label="Total Given" id={'total_given-'+id} key={'total_given-'+id}>
                                                <span class="dataSpace">
                                                    {line.total_given}
                                                </span>
                                            </td>
                                            <td class="slds-truncate" title="Given to Received Ratio" data-label="Ratio" id={'given_ratio-'+id} key={'given_ratio-'+id}>
                                                <span class="dataSpace">
                                                    {line.given_ratio}%
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            } else {
            leaderboard =
                <React.Fragment>
                    No leaderboard results found.
                </React.Fragment>
            }
        return (leaderboard);
    }
}

export default Leaderboard;
