import React, { Component } from "react";
import Leaderboard from "./leaderboard";

class Leaderboards extends Component {
    render() {
        return (
            <div class="slds-grid slds-wrap">
                <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2 slds-var-p-around_small">
                    <Leaderboard timeFrame="Quarterly"></Leaderboard>
                </div>
                <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2 slds-var-p-around_small">
                    <Leaderboard timeFrame="All-time"></Leaderboard>
                </div>
            </div>
        );
    }
}

export default Leaderboards;
