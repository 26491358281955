import React, { Component } from "react";
import MostRecentBurrito from "./mostRecentBurrito";
import UserInfo from "./userInfo";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId : localStorage.getItem('burritoUserId')
        }
    }
    render() {
        let home;
        if(this.state.userId) {
            home =
                <React.Fragment>
                    <div class="slds-grid slds-wrap slds-col slds-size_1-of-1 slds-medium-size_2-of-3 slds-large-size_3-of-4">
                        <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                            <div class="slds-var-p-around_small">
                                <MostRecentBurrito
                                    burritoMethod="Most Recent Lottery"
                                    label="Most Recent Lottery"
                                    creditLabel="Winner: "
                                    queryType="lottery">
                                </MostRecentBurrito>
                            </div>
                        </div>
                        <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_2-of-3">
                            <div class="slds-var-p-around_small">
                                <UserInfo></UserInfo>
                            </div>
                        </div>
                    </div>
                    <div class="slds-grid slds-grid_vertical slds-col slds-size_1-of-1 slds-medium-size_1-of-3 slds-large-size_1-of-4">
                        <div class="slds-col slds-var-p-around_small">
                            <MostRecentBurrito
                                burritoMethod="Given"
                                label="You Last Gave"
                                creditLabel="To: "
                                queryType="user">
                            </MostRecentBurrito>
                        </div>
                        <div class="slds-col slds-var-p-around_small">
                            <MostRecentBurrito
                                burritoMethod="Received"
                                label="You Last Received"
                                creditLabel="From: "
                                queryType="user">
                            </MostRecentBurrito>
                        </div>
                    </div>
                </React.Fragment>
        } else {
            home =
                <React.Fragment>
                    <p class="slds-text-heading_medium">Hmm...I can't find a user id for you. Try going back to Slack and clicking the "Website" button.</p>
                </React.Fragment>
        }
        return (
            <div class="slds-grid slds-wrap">
                {home}
            </div>
        );
    }
}

export default Home;
